import React from 'react';
import { TextAnime } from './animations/TextAnime';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { NavBar } from './NavBar';
import { NavBarrrr } from './NavBarrrr';
import { Footer } from './Footer';


export const Content = () => {
  return (
    <div className='relative bg-[#000000]/95 w-screen h-screen'>
      <NavBar />
      <NavBarrrr />
      <div className='flex relative lg:mb-12  lg:px-16 px-5 z-10 text-[#ffffff]'>
        <div className='relative lg:pt-40 py-44 lg:py-0'>
            <h1 aria-label=" Hi,  I’m TJ,  web developer" className='lg:text-7xl text-4xl font-black leading-25 font-serif relative tracking-wider'>
            <span aria-hidden="true" className='inline lg:inline-block opacity-100 hover:text-[#08fdd8] hover:scale-110 duration-300'>H</span>
            <span aria-hidden="true" className='inline lg:inline-block ease-out opacity-100 hover:text-[#08fdd8] hover:scale-110 duration-300'>i</span>
            <span aria-hidden="true" className='inline lg:inline-block opacity-100 hover:text-[#08fdd8] hover:scale-110 duration-300'>,</span><br></br>
            <span aria-hidden="true" className='inline lg:inline-block opacity-100 hover:text-[#08fdd8] hover:scale-110 duration-300'>I</span>
            <span aria-hidden="true" className='inline lg:inline-block opacity-100 hover:text-[#08fdd8] hover:scale-110 duration-300'>'</span>
            <span aria-hidden="true" className='inline lg:inline-block opacity-100 hover:text-[#08fdd8] hover:scale-110 duration-300'>m</span>&nbsp;
            <TextAnime />
            {/* <span aria-hidden="true" className='inline-block opacity-100 hover:text-[#08fdd8] hover:animate-pi'>T</span>
            <span aria-hidden="true" className='inline-block opacity-100 hover:text-[#08fdd8] hover:scale-110 duration-300'>J</span> */}
            <span aria-hidden="true" className='inline lg:inline-block opacity-100 hover:text-[#08fdd8] hover:scale-110 duration-300'>, </span><br></br>
            <span aria-hidden="true" className='inline lg:inline-block opacity-100 hover:text-[#08fdd8] hover:scale-110 duration-300'>w</span>
            <span aria-hidden="true" className='inline lg:inline-block opacity-100 hover:text-[#08fdd8] hover:scale-110 duration-300'>e</span>
            <span aria-hidden="true" className='inline lg:inline-block opacity-100 hover:text-[#08fdd8] hover:scale-110 duration-300'>b</span>&nbsp;   
            <span aria-hidden="true" className='inline lg:inline-block opacity-100 hover:text-[#08fdd8] hover:scale-110 duration-300'>d</span>
            <span aria-hidden="true" className='inline lg:inline-block opacity-100 hover:text-[#08fdd8] hover:scale-110 duration-300'>e</span>
            <span aria-hidden="true" className='inline lg:inline-block opacity-100 hover:text-[#08fdd8] hover:scale-110 duration-300'>v</span>
            <span aria-hidden="true" className='inline lg:inline-block opacity-100 hover:text-[#08fdd8] hover:scale-110 duration-300'>e</span>
            <span aria-hidden="true" className='inline lg:inline-block opacity-100 hover:text-[#08fdd8] hover:scale-110 duration-300'>l</span>
            <span aria-hidden="true" className='inline lg:inline-block opacity-100 hover:text-[#08fdd8] hover:scale-110 duration-300'>o</span>
            <span aria-hidden="true" className='inline lg:inline-block opacity-100 hover:text-[#08fdd8] hover:scale-110 duration-300'>p</span>
            <span aria-hidden="true" className='inline lg:inline-block opacity-100 hover:text-[#08fdd8] hover:scale-110 duration-300'>e</span>
            <span aria-hidden="true" className='inline lg:inline-block opacity-100 hover:text-[#08fdd8] hover:scale-110 duration-300'>r</span>
            <span aria-hidden="true" className='inline lg:inline-block opacity-100 hover:text-[#08fdd8] hover:scale-110 duration-300'>!</span>
            </h1>
            {/* <motion.p className='text-xl tracking-widest my-2 text-gray-400 hover:text-[#08fdd8]'
             initial={{ y: -20, opacity: 0}}
             animate={{ y: 0, opacity: 1}}
             transition={{duration: 1.5}}>Front End Developer</motion.p> */}
            <motion.p className='text-xl tracking-widest my-6 lg:w-[50rem] mr-8 text-[#d4d2d2] hover:text-[#08fdd8]'
              initial={{ y: 20, opacity: 0}}
              animate={{ y: 0, opacity: 1}}
              transition={{type: 'spring', duration: 3, bounce: 0.3, delay: 1.5}}
            >I'm a Frontend Developer who brings ideas to life by creating effective, captivating and responsive websites with a focus on user friendliness and elegant interfaces  </motion.p><br></br>
            <motion.div initial={{ x:'-100vw'}}
              animate={{ x: 0}}
              transition={{type: 'spring', duration: 5, bounce: 0.3, delay: 2}}>
             <Link to='/contact' smooth className='text-[#08fdd8] text-xl px-6 py-3 justify-center text-center bg-[#1d1d1d] border-solid rounded-md border-4 border-[#08fdd8] hover:bg-[#08fdd8] hover:text-[#1d1d1d] hover:transition hover:duration-200 mb-8'>Contact me!</Link>
            </motion.div>
        </div>
      </div>
      <Footer className='flex justify-end items-end'/>
    </div>
  )
}
