import React from 'react';


export const Footer = () => {
  return (
    <div className='text-center justify-center'>
      <h1 className='text-[#08fdd8] lg:text-lg text-base tracking-widest p-4'>@2023 Made by Tijani.</h1>
    </div>
  )
}
