import React from 'react';
import { Routes, Route} from 'react-router-dom';
import { Content } from './components/Content';
import { Content2 } from './components/Content2';
import { Content3 } from './components/Content3';
import { Content4 } from './components/Content4';



function App() {

  return (
        <Routes>
          <Route path='/' element={<Content />}></Route>
          <Route path='/portfolio' element={<Content2 />}></Route>
          <Route path='/about-me' element={<Content3 />}></Route>
          <Route path='/contact' element={<Content4 />}></Route>
        </Routes>
      //   <div className='bg-[#000000]/95 w-screen overflow-x-hidden overflow-y-scroll border-box opacity-100'>
      //     <NavBar />
      //     <NavBarrrr />
      //     <Content />
      //     <Content2 />
      //     <Content3 />
      //     <Content4 />
      //     <Footer />
      // </div>
  )
};

export default App;