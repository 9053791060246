import React from 'react';
import styled from 'styled-components';
import icon1  from '../img/javascript.png';
import icon2  from '../img/react.png';
import icon3  from '../img/tailwind.png';
import icon4  from '../img/typescript.png';
import icon5  from '../img/node.png';
import icon6  from '../img/redux.png';
import icon7  from '../img/git.png';
import icon8  from '../img/native.png';
import icon9  from '../img/bootstrap.png';
import icon10  from '../img/vite.png';
import icon11  from '../img/graphql.png';
import icon12  from '../img/nextjs.png';


export const CardAnime = () => {
  return (
    <Wrapper>
        <CardWrapper>
            <Card className='relavtive flex flex-col lg:space-x-4 space-x-2 items-center'>
                <span className='flex items-center justify-center flex-col gap-2'>
                    <img alt='logo' src={icon1} className='items-center lg:w-12 lg:h-12 w-8 h-8'></img>
                    <p className='text-[#d4d2d2] font-medium text-base items-center'>JAVASCRIPT</p>
                </span>
            </Card>
            <Card className='relavtive flex flex-col lg:space-x-4 space-x-2 items-center '>
                <span className='flex items-center justify-center flex-col gap-2'>
                    <img alt='logo' src={icon2} className='items-center lg:w-12 lg:h-12 w-8 h-8'></img>
                    <p className='text-[#d4d2d2] font-medium text-base items-center'>REACT Js</p>
                </span>
            </Card>
            <Card className='relavtive flex flex-col lg:space-x-4 space-x-2 items-center'>
                <span className='flex items-center justify-center flex-col gap-2'>
                    <img alt='logo' src={icon3} className='items-center lg:w-12 lg:h-12 w-8 h-8'></img>
                    <p className='text-[#d4d2d2] font-medium text-base items-center'>TAILWIND CSS</p>
                </span>
            </Card>
            <Card className='relavtive flex flex-col lg:space-x-4 space-x-2 items-center'>
                <span className='flex items-center justify-center flex-col gap-2'>
                    <img alt='logo' src={icon4} className='items-center lg:w-12 lg:h-12 w-8 h-8'></img>
                    <p className='text-[#d4d2d2] font-medium text-base items-center'>TYPESCRIPT</p>
                </span>
            </Card>
            <Card className='relavtive flex flex-col lg:space-x-4 space-x-2 items-center' >
                <span className='flex items-center justify-center flex-col gap-2'>
                    <img alt='logo' src={icon5} className='items-center lg:w-12 lg:h-12 w-8 h-8'></img>
                    <p className='text-[#d4d2d2] font-medium text-base items-center uppercase'>Node Js</p>
                </span>
            </Card>
            <Card className='relavtive flex flex-col lg:space-x-4 space-x-2 items-center '>
                <span className='flex items-center justify-center flex-col gap-2'>
                    <img alt='logo' src={icon6} className='items-center lg:w-12 lg:h-12 w-8 h-8'></img>
                    <p className='text-[#d4d2d2] font-medium text-base items-center uppercase'>Redux</p>
                </span>
            </Card>
            <Card className='relavtive flex flex-col lg:space-x-4 space-x-2 items-center'>
                <span className='flex items-center justify-center flex-col gap-2'>
                    <img alt='logo' src={icon8} className='items-center lg:w-12 lg:h-12 w-8 h-8'></img>  
                    <p className='text-[#d4d2d2] font-medium text-base items-center uppercase'>React Native</p>
                </span>
            </Card>
            <Card className='relavtive flex flex-col items-center' >
                <span className='flex items-center justify-center flex-col gap-2'>
                    <img alt='logo' src={icon7} className='items-center lg:w-12 lg:h-12 w-8 h-8'></img>
                    <p className='text-[#d4d2d2] font-medium text-base'>GIT</p>
                </span>
            </Card>
            <Card className='relavtive flex flex-col lg:space-x-4 space-x-2 items-center '>
                <span className='flex items-center justify-center flex-col gap-2'>
                    <img alt='logo' src={icon9} className='items-center lg:w-12 lg:h-12 w-8 h-8'></img>
                    <p className='text-[#d4d2d2] font-medium text-base items-center'>BOOTSTRAP</p>
                </span>
            </Card>
            <Card className='relavtive flex flex-col lg:space-x-4 space-x-2 items-center'>
                <span className='flex items-center justify-center flex-col gap-2'>
                    <img alt='logo' src={icon10} className='items-center lg:w-12 lg:h-12 w-8 h-8'></img>
                    <p className='text-[#d4d2d2] font-medium text-base items-center'>VITE</p>
                </span>
            </Card>
            <Card className='relavtive flex flex-col lg:space-x-4 space-x-2 items-center'>
                <span className='flex items-center justify-center flex-col gap-2'>
                    <img alt='logo' src={icon11} className='items-center lg:w-12 lg:h-12 w-8 h-8'></img>
                    <p className='text-[#d4d2d2] font-medium text-base items-center'>GRAPHQL</p>
                </span>
            </Card>
            <Card className='relavtive flex flex-col lg:space-x-4 space-x-2 items-center' >
                <span className='flex items-center justify-center flex-col gap-2'>
                    <img alt='logo' src={icon12} className='items-center flex-nowrap lg:w-12 lg:h-12 w-8 h-8'></img>
                    <p className='text-[#d4d2d2] font-medium text-base items-center fle-x'>Next Js</p>
                </span>
            </Card>
            
        </CardWrapper>
    </Wrapper>
  )
}


const Wrapper = styled.div`
display: flex;
padding: 2rem;
justify-content: center;
@media (max-width: 640px) {
    padding: 0px;

}
`
const CardWrapper = styled.div`
display: flex;
flex-wrap: wrap;
justify-content: center;
gap: 60px 5px;
padding: 50px 0px;
pointer-events: none;
border-width: 1px;
border-radius: 12px;
border-color: transparent;
box-shadow: rgba(0, 0, 0, 16.2) 0px 1px 4px;
border-style: solid;
box-sizing: border-box;
@media (max-width: 640px) {
    flex-wrap: wrap-reverse; 
    
}
`
const Card = styled.div`

padding: 10px 20px 15px 20px;
width: 10rem;
cursor: pointer;
transition: all 150ms ease-in-out;
@media (max-width: 640px) {
    padding: 8px;
    transfrom: scale(0.5, 0.5)
}
`