import React, { useRef } from 'react';
import emailjs  from '@emailjs/browser';
import { FaEnvelope, FaTwitter } from 'react-icons/fa';
import { BsPhone, BsGithub, BsWhatsapp, BsLinkedin } from 'react-icons/bs';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useEffect } from 'react';
import { useAnimation } from 'framer-motion';
import { NavBar } from './NavBar';
import { NavBarrrr } from './NavBarrrr';
import { Footer } from './Footer';


export const Content4 = () => {
    const form = useRef();

    const {ref, inView} = useInView();
    const animation = useAnimation();

    useEffect(() => {
      console.log('use effect hook, inView = ', inView);
      if(inView){
        animation.start({
          x: 0,
          opacity: 1,
          transition: {
            type: 'spring', duration: 3, bounce: 0.3, delay: 0.3
          }
        })
      }
      if(!inView){
        animation.start({x: '20vw', opacity: 0})
      }
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inView]);

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_ndmgtaj', 'template_z4fozk1', form.current, 'B9MRGWd47gnhHxvKW')
        .then((result) => {
            console.log(result.text);
            e.target.reset();
        }, (error) => {
            console.log(error.text);
        });
    };

    const AnimatedLetters = ({title}) => (
        <motion.span className='flex flex-row' variants={wrapper} initial='initial' animate='animate'>
          {[...title].map((letter) => (
            <motion.span className='flex lg:text-5xl text-3xl font-bold uppercase text-[#08fdd8] tracking-widest' variants={letterAnimation}>{letter}</motion.span>
          ))}
        </motion.span>
      );
    
      const wrapper = {
        animate: { 
          transition: {
            delayChildren: 0.4,
            staggerChildren: 0.2,
        },},
      };
    
      const letterAnimation = {
        initial: {
            opacity: 0,
          },
          animate: {
            opacity: 1,
            // rotate: 360,
            transition: {
              ease:[0.6, 0.01, -0.05, 0.95],
              // ease: 'circInOut',
              type: 'spring',
              duration: 1.5,
              bounce: 0.4,
            }
          }
      }

    

  return (
    <div className='relative bg-[#000000]/95 w-screen h-fit'>
      <NavBar />
      <NavBarrrr />
      <div className='overflow-hidden flex items-center relative py-5 px-5 lg:px-20' id='contact'>
        <div className='relative flex flex-col  w-full' ref={ref}>
            <motion.header className='flex flex-row space-x-4 mb-2 pt-8 lg:items-center lg:justify-center' variants={wrapper}>
                <AnimatedLetters title={'contact'}/>
                <AnimatedLetters title={'me'}/>
                {/* <TiContacts size={70} color='#08fdd8' className='z-0'/> */}
            </motion.header>
            <div className='flex lg:flex-row flex-col gap-8 lg:py-12 py-4'>
              <div className='flex flex-col gap-5'>
                <span className='uppercase text-3xl font-medium text-[#d4d2d2]'>let's discuss</span>
                <p className='text-lg text-[#d4d2d2]/70 lg:w-[32rem]'>Feel free to get in touch with me. I am always open to discussing new projects, creative ideas or opportunities to be part of your visions.</p>
                <span className='flex gap-3'>
                  <FaEnvelope className='w-10 h-10 text-[#d4d2d2] '/>
                  <span className='flex flex-col'>
                    <span className='uppercase text-base text-[#d4d2d2] leading-4'>mail me</span>
                    <span className='text-base font-medium text-[#d4d2d2]/70'>tijani.tofunmi@gmail.com</span>
                  </span>
                </span>
                <span className='flex gap-3'>
                  <BsPhone className='w-10 h-10 text-[#d4d2d2] '/>
                  <span className='flex flex-col'>
                    <span className='uppercase text-base text-[#d4d2d2] leading-4'>call me</span>
                    <span className='text-base font-medium text-[#d4d2d2]/70'>+234 810 268 4993</span>
                  </span>
                </span>
                <div className='flex flex-row space-x-12 mt-4'>
                    <a href='https://github.com/Thieejhay' target='_blank' rel='noreferrer' className='text-2xl leading-6 cursor-pointer ease-out text-[#d4d2d2]'><BsGithub /></a>
                    <a href='https://www.linkedin.com/in/tofunmi-tijani-1a796024a/' target='_blank' rel='noreferrer' className='text-2xl leading-6 cursor-pointer ease-out text-[#d4d2d2]'><BsLinkedin /></a>
                    <a href='https://wa.me/2348102684993ß' target='_blank' rel='noreferrer' className='text-2xl leading-6 cursor-pointer ease-out text-[#d4d2d2]'><BsWhatsapp /></a>
                    <a href='https://twitter.com/tijaniix' target='_blank' rel='noreferrer' className='text-2xl leading-6 cursor-pointer ease-out text-[#d4d2d2]'><FaTwitter /></a>
                </div>
              </div>
              <motion.div className=' relative flex shadow-lg' animate={animation}>
                      <form className='relative' ref={form} onSubmit={sendEmail}>
                          <ul className=' gap-4 flex flex-col'>
                              <li className='lg:w-[30rem] w-[20rem] border-[#08fdd8] border-2 rounded-lg'>
                                  <input className='w-full b-0 h-[50px] text-sm border-box px-[20px] bg-transparent text-[#8d8d8d]' text='text' placeholder='Your Full Name' name='user_name'></input>
                              </li>
                              <li className='lg:w-[30rem] w-[20rem] border-[#08fdd8] border-2 rounded-lg'>
                                  <input className='w-full b-0 h-[50px] text-sm border-box px-[20px] bg-transparent text-[#8d8d8d]'type='email' placeholder='Your Email' name='user_email'></input>
                              </li>
                              <li className='lg:w-[30rem] w-[20rem] border-[#08fdd8] border-2 rounded-lg'>
                                  <input className='w-full b-0 h-[50px] text-sm border-box px-[20px] bg-transparent text-[#8d8d8d]' type='text' placeholder='Your Subject' name='Subject'></input>
                              </li>
                              <li className='lg:w-[30rem] w-[20rem] border-[#08fdd8] border-2 rounded-lg'>
                                  <textarea className='w-full b-0 h-[75px] text-sm border-box px-[20px] bg-transparent text-[#8d8d8d]' placeholder='Your Message' name='message'>
                                  </textarea>
                              </li>
                              <li className='relative'>
                                  <input type='submit' value='Send Message' className='text-[#08fdd8] text-xl px-10 py-4 justify-center text-center bg-[#2b2b2b] rounded-md hover:bg-[#08fdd8] hover:text-[#2b2b2b] hover:transition hover:duration-200' />
                              </li>
                          </ul>
                      </form>
              </motion.div>
            </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
