import React from 'react'
import { Cards } from './Cards'
// import Data from './Data'
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useEffect } from 'react';
import { useAnimation } from 'framer-motion';
// import img1  from '../img/img1.png';
import img2 from '../img/img2.png';
import img3 from '../img/maid.png'
import img4 from '../img/temple.png';
import img5 from '../img/hilary.png';
import img6 from '../img/img6.png';
import img7 from '../img/trucrete.png';
import { NavBar } from './NavBar';
import { NavBarrrr } from './NavBarrrr';
import { Footer } from './Footer';





export const Content2 = () => {

  const {ref, inView} = useInView();
  const animation = useAnimation();
  const animations = useAnimation();
  const animationss = useAnimation();
  const animationsss = useAnimation();
  const animationssss = useAnimation();
  const animationsssss = useAnimation();

  useEffect(() => {
    console.log('use effect hook, inView = ', inView);
    if(inView){
      animation.start({
        x: 0,
        transition: {
          type: 'spring', duration: 4, bounce: 0.3
        }
      })
    }
    if(!inView){
      animation.start({x: '-100vw'})
    }
    if(inView){
      animations.start({
        x: 0,
        transition: {
          type: 'spring', duration: 4, bounce: 0.3, delay: 1
        }
      })
    }
    if(!inView){
      animations.start({x: '100vw'})
    }
    if(inView){
      animationss.start({
        x: 0,
        transition: {
          type: 'spring', duration: 4, bounce: 0.3, delay: 1.5
        }
      })
    }
    if(!inView){
      animationss.start({x: '-100vw'})
    }
    if(inView){
      animationsss.start({
        x: 0,
        transition: {
          type: 'spring', duration: 4, bounce: 0.3, delay: 2
        }
      })
    }
    if(!inView){
      animationsss.start({x: '100vw'})
    }
    if(inView){
      animationssss.start({
        x: 0,
        transition: {
          type: 'spring', duration: 4, bounce: 0.3, delay: 2.5
        }
      })
    }
    if(!inView){
      animationssss.start({x: '-100vw'})
    }
    if(inView){
      animationsssss.start({
        x: 0,
        transition: {
          type: 'spring', duration: 4, bounce: 0.3, delay: 2.5
        }
      })
    }
    if(!inView){
      animationsssss.start({x: '100vw'})
    }


  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  const AnimatedLetters = ({title}) => (
    <motion.span className='flex flex-row' variants={wrapper} initial='initial' animate='animate'>
      {[...title].map((letter) => (
        <motion.span className='flex lg:text-5xl text-3xl font-bold uppercase text-[#08fdd8] tracking-wider' variants={letterAnimation}>{letter}</motion.span>
      ))}
    </motion.span>
  );

  const wrapper = {
    animate: { 
      transition: {
        delayChildren: 0.4,
        staggerChildren: 0.2,
    },},
  };

  const letterAnimation = {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
      // rotate: 360,
      transition: {
        ease:[0.6, 0.01, -0.05, 0.95],
        // ease: 'circInOut',
        type: 'spring',
        duration: 1.5,
        bounce: 0.4,
      }
    }
  }


  return (
    <div className='relative bg-[#000000]/95 w-screen'>
      <NavBar />
      <NavBarrrr />
      <div className='p-5 min-h-0 relative transition-opacity delay-400 lg:py-12 py-16  px-5 lg:px-20'>
        <div className='flex lg:p-4 relative '>
            <div className='relative ' ref={ref}> 
                <motion.header className='flex lg:justify-center lg:items-center' variants={wrapper}>
                    <AnimatedLetters title={'projects'}/>
                </motion.header>
                <p className='relative lg:mt-4 mt-2 text-xl lg:py-2 lg:px-20 text-[#d4d2d2] lg:text-center'>
                A small gallery of some things i've built mainly with JavaScript, Tailwindcss, React.js, React Native and APIs. Ranging from fully functional webapps to landing pages and websites. There's more from where these came from.
                </p>
                {/* {card} */}
                <div className='flex flex-wrap gap-x-4 gap-y-8 py-8 justify-center lg:items-center'>
                  <div ref={ref}>
                    <a target='_blank' rel='noreferrer' href='https://www.templeschoolng.com/'>
                      <div animate={animationss}>
                        <Cards id={2} title='Temple Schools' stack='React js, Tailwind css' desc='A fully functional School website' src={img4}/>
                      </div>
                     </a>
                  </div>
                  <div ref={ref}>
                    <a target='_blank' rel='noreferrer' href='https://hilarysays.com'>
                      <div animate={animations}>
                        <Cards id={1} title='E-Commerce' stack='Javascript CSS Shopify' desc='A live skincare sale website where you preview, book consultation and add your orders to cart' src={img5}/>
                      </div>
                     </a>
                  </div>
                  <div ref={ref}>
                    <a target='_blank' rel='noreferrer' href='https://edenventuregroup.com'>
                      <div animate={animation}>
                        <Cards id={1} title='Eden Venture Group' stack='React js, Tailwind css framer-motion' desc='A non-profit organization company website' src={img6}/>
                      </div>
                    </a>
                  </div>
                  <div ref={ref}>
                     <a target='_blank' rel='noreferrer' href='https://trucreteng.com/'>
                        <div animate={animationsssss}>
                          <Cards id={7} title='Trucrete solution limited' stack='React js, Tailwind css framer-motion' desc='Web pages for a construction company' src={img7}/>
                        </div>
                      </a>
                  </div>
                  <div ref={ref}>
                    <a target='_blank' rel='noreferrer' href='https://expo.dev/@thieejhay24/delivery-me'>
                      <div animate={animationsss}>
                        <Cards id={2} title='Delivery APP' stack='React native, Tailwind css, React Redux, sanity.io' desc='A delivery project built with React Native and Sanity.io as backend.' src={img2}/>
                      </div>
                    </a>
                  </div>
                  <div ref={ref}>
                    <a target='_blank' rel='noreferrer' href='https://maidstone-energy.com'>
                      <div animate={animationssss}>
                        <Cards id={3} title='Maidstone Energy' stack='React js, Tailwind css' desc='Landing page for Maidstone Energy website' src={img3}/>
                      </div>
                     </a>
                  </div>
                  {/* <div ref={ref}>
                     <a target='_blank' rel='noreferrer' href='https://fitgymclub-2lm.pages.dev/'>
                        <div animate={animationsssss}>
                          <Cards id={3} title='FitFamClub' stack='React js, Tailwind css framer-motion' desc='A gym website' src={img1}/>
                        </div>
                      </a>
                  </div> */}
                </div>
            </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
